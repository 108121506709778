import { reactive } from "vue";

const theme = reactive({
    agentLogo: "Footer.png",
    devLogo: "Header.png",
    apiUrl: "https://api.metaluxe.com.au/",
    bunnyApiUrl: "https://api.bunny.net/",
    accessKey: "07aeb6d1-92b0-48fc-9fa3-e202bc32d8753a016979-9f0b-4b1d-8027-5575c13bad47",
    streamToken: "349deead-eb98-48cb-a775-bc4585b85b20",
    videoLibraryId: "134006",
    streamCdnUrl: "https://vz-18f98568-2c5.b-cdn.net",
    streamApiKey: "da72182e-bb90-4ed4-92286589e6a4-a1e0-48f4",
    storageApiPassword: "76d0512d-6819-41af-b2f85bb5dfab-d559-4d31",
    cdnUrl: "https://amara-pwa.b-cdn.net",
    devFolder: "/amara-pwa",
    googleAnalytics: "",
    videosIcon: "/img/ui-theme/videos-icon.svg",
    rendersIcon: "/img/ui-theme/renders-icon.svg",
    rangeIcon: "/img/ui-theme/range-icon.svg",
    panoramasIcon: "/img/ui-theme/panoramas-icon.svg",
    floorplansIcon: "/img/ui-theme/floorplans-icon.svg",
    dashboardMp4: "theme/dashboard/4K_dashboard.mp4",

    // top level menu
    assetsMenuOrder: [
        'Monaco',
        'Metaluxe Showcase',
        'Vista',
        'Riverlea'
    ],

    bed1MenuOrder: [
        'videos',
        'virtual-tour',
        'balcony-views',
        'floorplans',
        'range',
        'renders',
        'pdf'
    ],

    bed1Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Interior Virtual Tour'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/5phsXhF-B'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed2Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [],
        panoramasUrl: [],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed3Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Vista 3 Bed'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/5phsXhF-B'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },

    bed4Asset: {
        videos: [],
        videosName: [],
        panoramas: [0],
        panoramasName: [
            'Riverlea Boulevard Demo'
        ],
        panoramasUrl: [
            'https://app.cloudpano.com/tours/2T2ciQRdd'
        ],
        balcony: [0],
        balconyName: [],
        balconyUrl: [],
        renders: [],
        rendersName: [],
        rendersUrl: [],
        floorplans: [],
        floorplansName: [],
        floorplansUrl: [],
        range: [],
        rangeName: [],
        rangeUrl: [],
        pdf: [0],
        pdfName: [],
        pdfUrl: [],
    },
});

export default { theme };
